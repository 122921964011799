<template>
  <div>
    <div class="flex-shrink-0 p-3 text-start" style="width: 250px;">
      <router-link to="/portal" class="d-flex align-items-center pb-3 mb-3 link-body-emphasis text-decoration-none border-bottom">
        <svg class="bi me-2" width="30" height="24"><use xlink:href="#bootstrap"/></svg>
        <span><img src="../assets/logo_nav.png" style="width: 170px;"></span>
      </router-link>
      <div v-if="session.session" style="position: absolute; top: 70px; left: 5px; bottom: 50px; width: 250px; overflow: auto;">
        <div v-if="session.session.green && (session.session.blue || session.session.red)" style="margin-left: 10px; margin-bottom: 3px;" class="text-center">
<!--          <div class="form-check form-check-inline">-->
<!--            <input v-model="store.showBlue" class="form-check-input" type="checkbox" id="showMenuB">-->
<!--            <label class="form-check-label" for="showMenuB" style="margin-left: -4px;">-->
<!--              <i class="fas fa-bookmark fa-fw" style="color: #0097E0; margin-top: 4px;"></i>-->
<!--            </label>-->
<!--          </div>-->
          <div class="form-check form-check-inline" style="margin-left: -3px;">
            <input v-model="store.showRed" class="form-check-input" type="checkbox" id="showMenuR">
            <label class="form-check-label" for="showMenuR" style="margin-left: -4px;">
              <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 4px;"></i>
            </label>
          </div>
<!--          <div class="form-check form-check-inline" style="margin-left: -3px;">-->
<!--            <input v-model="store.showPink" class="form-check-input" type="checkbox" id="showMenuP">-->
<!--            <label class="form-check-label" for="showMenuP" style="margin-left: -4px;">-->
<!--              <i class="fas fa-bookmark fa-fw" style="color: #EE86A8; margin-top: 4px;"></i>-->
<!--            </label>-->
<!--          </div>-->
          <div class="form-check form-check-inline" style="margin-left: -3px;">
            <input v-model="store.showGreen" class="form-check-input" type="checkbox" id="showMenuG">
            <label class="form-check-label" for="showMenuG" style="margin-left: -4px;">
              <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 4px;"></i>
            </label>
          </div>
        </div>
<!--        <ul class="nav nav-tabs" style="margin-bottom: 10px;">-->
<!--          <li class="nav-item">-->
<!--            <a @click="menuMode = 'normal'" class="nav-link" :class="{ 'active': menuMode === 'normal' }" aria-current="page" href="#">-->
<!--              通常-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item">-->
<!--            <a @click="menuMode = 'guide'" class="nav-link" :class="{ 'active': menuMode === 'guide' }" aria-current="page" href="#">-->
<!--              ガイド-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
        <template v-if="menuMode === 'guide'">
          <ul class="list-unstyled ps-0">
            <li class="mb-1">
              <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#person-collapse" aria-expanded="true">
                <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                権利者登録
              </button>
              <div class="collapse show" id="person-collapse">
                <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <router-link to="/common/artist/list" class="text-dark d-inline-flex text-decoration-none rounded">
                      <span class="badge bg-info" style="margin-right: 5px;">1</span>
                      capo/contano権利者の送付区分をオンラインに変更
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/common/artist/list" class="text-dark d-inline-flex text-decoration-none rounded">
                      <span class="badge bg-info" style="margin-right: 5px;">2</span>
                      権利者への登録依頼
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/common/artist/list" class="text-dark d-inline-flex text-decoration-none rounded">
                      <span class="badge bg-info" style="margin-right: 5px;">3</span>
                      送付先の確認
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="mb-1">
              <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#person-collapse" aria-expanded="true">
                <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                受領者の変更・追加
              </button>
            </li>
          </ul>
        </template>
        <ul v-if="menuMode === 'normal'" class="list-unstyled ps-0">
          <li v-if="session.session && session.session.red && store.showRed && session.session.company" class="mb-1">
            <button @click="store.showWorkMenu = !store.showWorkMenu" class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#master-collapse" :aria-expanded="store.showWorkMenu">
              <i class="fas fa-album-collection fa-fw" style="margin-right: 5px;"></i>作品
            </button>
            <div class="collapse" :class="{'show': store.showWorkMenu}" id="master-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li v-if="session.session.red && store.showRed">
                  <router-link to="/red/album/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    アルバム
                  </router-link>
                </li>
                <li v-if="session.session.red && store.showRed">
                  <router-link to="/red/master/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    原盤
                  </router-link>
                </li>
                <li>
                  <router-link to="/common/project/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #0097E0; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    プロジェクト
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="session.session && session.session.red && store.showRed && session.session.company" class="mb-1">
            <button @click="store.showPersonMenu = !store.showPersonMenu" class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#person-collapse" :aria-expanded="store.showPersonMenu">
              <i class="fas fa-people fa-fw" style="margin-right: 5px;"></i>人物・会社
            </button>
            <div class="collapse" :class="{'show': store.showPersonMenu}" id="person-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li v-if="session.session.red && store.showRed">
                  <router-link to="/red/rightsholder/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    RED権利者
                  </router-link>
                </li>
                <li>
                  <router-link to="/common/artist/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #0097E0; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    アーティスト
                  </router-link>
                </li>
                <li v-if="session.session.red && store.showRed">
                  <router-link to="/red/user/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    利用者
                  </router-link>
                </li>
                <li>
                  <router-link to="/common/addressee/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #0097E0; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #EE86A8; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    送付先
                  </router-link>
                </li>
                <li>
                  <router-link to="/common/payee/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #0097E0; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #EE86A8; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    振込先
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="session.session && session.session.red && store.showRed && session.session.company" class="mb-1">
            <button @click="store.showContractMenu = !store.showContractMenu" class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#contract-collapse" :aria-expanded="store.showContractMenu">
              <i class="fas fa-handshake fa-fw" style="margin-right: 5px;"></i>契約
            </button>
            <div class="collapse" :class="{'show': store.showContractMenu}" id="contract-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <router-link to="/red/contract/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    RED契約書
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="session.session && session.session.red && store.showRed && session.session.company" class="mb-1">
            <button @click="store.showRedCalcMenu = !store.showRedCalcMenu" class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#calc-collapse" :aria-expanded="store.showRedCalcMenu">
              <i class="fas fa-calculator-simple fa-fw" style="margin-right: 5px;"></i>RED計算
              <span v-if="(redUmErrorCount + redNdErrorCount + redUseTypeErrorCount) > 0" class="badge bg-danger" style="border-radius: 3px; position: relative; top: -2px; margin-left: 3px;">{{ (redUmErrorCount + redNdErrorCount + redUseTypeErrorCount) }}</span>
            </button>
            <div class="collapse" :class="{'show': store.showRedCalcMenu}" id="calc-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <router-link to="/red/usage/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    使用実績
                  </router-link>
                </li>
                <li>
                  <router-link to="/red/usage/detail/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    実績データ詳細
                  </router-link>
                </li>
                <li>
                  <router-link to="/red/error/unmatch" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    アンマッチ
                    <span v-if="redUmErrorCount > 0" class="badge bg-danger" style="border-radius: 3px; position: relative; top: -2px; margin-left: 3px;">{{ redUmErrorCount }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/red/error/notdiv" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    契約書未設定
                    <span v-if="redNdErrorCount > 0" class="badge bg-danger" style="border-radius: 3px; margin-left: 3px;">{{ redNdErrorCount }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/red/error/usetype" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    利用種別不明
                    <span v-if="redUseTypeErrorCount > 0" class="badge bg-danger" style="border-radius: 3px; margin-left: 3px;">{{ redUseTypeErrorCount }}</span>
                  </router-link>
                </li>
                <li>
                  <router-link to="/red/result/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    リアルタイム結果
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="session.session && session.session.red && store.showRed && session.session.company" class="mb-1">
            <button @click="store.showSupportMenu = !store.showSupportMenu" class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#support-collapse" :aria-expanded="store.showSupportMenu">
              <i class="fas fa-gears fa-fw" style="margin-right: 5px;"></i>補助
            </button>
            <div class="collapse" :class="{'show': store.showSupportMenu}" id="support-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li v-if="session.session.red && store.showRed">
                  <router-link to="/red/setting/disp" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    RED基本設定
                  </router-link>
                </li>
                <li>
                  <router-link to="/common/bank/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #0097E0; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #EE86A8; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    銀行
                  </router-link>
                </li>
                <li>
                  <router-link to="/common/currency/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    通貨
                  </router-link>
                </li>
                <li v-if="session.session.red && store.showRed">
                  <router-link to="/red/usetype/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    利用種別
                  </router-link>
                </li>
                <li v-if="session.session.red && store.showRed">
                  <router-link to="/red/royaltytype/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    印税種別
                  </router-link>
                </li>
                <li>
                  <router-link to="/red/definition/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    取込ファイル定義
                  </router-link>
                </li>
                <li>
                  <router-link to="/red/payment/pdf/definition/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    RED分配明細PDF定義
                  </router-link>
                </li>
                <li>
                  <router-link to="/red/payment/data/definition/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    RED分配データ定義
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="session.session && ((session.session.red && store.showRed && session.session.company) || (session.session.green && store.showGreen))" class="mb-1">
            <button @click="store.showPaymentMenu = !store.showPaymentMenu" class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#payment-collapse" :aria-expanded="store.showPaymentMenu">
              <i class="fas fa-money-bill-1 fa-fw" style="margin-right: 5px;"></i>支払
            </button>
            <div class="collapse" :class="{'show': store.showPaymentMenu}" id="payment-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li v-if="session.session.red && store.showRed">
                  <router-link to="/red/payment/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    RED支払明細書
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/statement" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    明細送付
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen && session.session.greenOptionExternal">
                  <router-link to="/green/external" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    メッセージ送信
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/history" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    送信履歴
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/receipt" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    受領
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/destination/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    送付先
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/rightsholder/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    登録依頼
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="session.session && !session.session.red && session.session.company && (session.session.green && store.showGreen) && session.session.contractId" class="mb-1">
            <button @click="store.showPaymentMenu = !store.showPaymentMenu" class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#payment-collapse" :aria-expanded="store.showPaymentMenu">
              <i class="fas fa-money-bill-1 fa-fw" style="margin-right: 5px;"></i>支払
            </button>
            <div class="collapse show" id="payment-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/statement" class="text-dark d-inline-flex text-decoration-none rounded">
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    明細送付
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen && session.session.greenOptionExternal">
                  <router-link to="/green/external" class="text-dark d-inline-flex text-decoration-none rounded">
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    メッセージ送信
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/history" class="text-dark d-inline-flex text-decoration-none rounded">
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    送信履歴
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/receipt" class="text-dark d-inline-flex text-decoration-none rounded">
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    受領
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/destination/list" class="text-dark d-inline-flex text-decoration-none rounded">
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    送付先
                  </router-link>
                </li>
                <li v-if="session.session.green && store.showGreen">
                  <router-link to="/green/rightsholder/list" class="text-dark d-inline-flex text-decoration-none rounded">
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    <!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #8EC31F; margin-top: 3px; margin-left: -3px;"></i>
                    収集用権利者
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="!session.session.contractId" class="mb-1">
            <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#payment-collapse" :aria-expanded="true">
              <i class="fas fa-money-bill-1 fa-fw" style="margin-right: 5px;"></i>明細
            </button>
            <div class="collapse show" id="payment-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <router-link to="/green/receipt" class="text-dark d-inline-flex text-decoration-none rounded">
                    受領明細一覧
                  </router-link>
                </li>
                <li>
                  <router-link to="/green/sender/list" class="text-dark d-inline-flex text-decoration-none rounded">
                    送信元一覧
                  </router-link>
                </li>
                <li>
                  <router-link to="/green/organization/list" class="text-dark d-inline-flex text-decoration-none rounded">
                    受領グループ
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="session.session && session.session.red && store.showRed && session.session.company" class="mb-1">
            <button @click="store.showTermMenu = !store.showTermMenu" class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#term-collapse" :aria-expanded="store.showTermMenu">
              <i class="fas fa-calendar-alt fa-fw" style="margin-right: 5px;"></i>期別・年度
            </button>
            <div class="collapse" :class="{'show': store.showTermMenu}" id="term-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <router-link to="/red/term/update" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    RED期締め
                  </router-link>
                </li>
                <li>
                  <router-link to="/red/paymentrecord/list" class="text-dark d-inline-flex text-decoration-none rounded">
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #E83821; margin-top: 3px; margin-left: -3px;"></i>
<!--                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>-->
                    <i class="fas fa-bookmark fa-fw" style="color: #dddddd; margin-top: 3px; margin-left: -3px;"></i>
                    RED支払調書
                  </router-link>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="session.session.hasAdminRole === 'YES'" class="mb-1">
            <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#admin-collapse" aria-expanded="true">
              <i class="fas fa-cog" style="margin-right: 5px;"></i>管理者
            </button>
            <div class="collapse show" id="admin-collapse">
              <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <router-link to="/admin/contract/list" class="text-dark d-inline-flex text-decoration-none rounded" href="#">契約</router-link>
                </li>
                <li>
                  <router-link to="/admin/user/list" class="text-dark d-inline-flex text-decoration-none rounded" href="#">ユーザー</router-link>
                </li>
                <li>
                  <router-link to="/admin/message/list" class="text-dark d-inline-flex text-decoration-none rounded" href="#">お知らせ</router-link>
                </li>
                <li><router-link to="/admin/postal/register" class="text-dark d-inline-flex text-decoration-none rounded" href="#">郵便番号データ</router-link></li>
                <li>
                  <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#convert-collapse" aria-expanded="true" style="font-weight: normal; margin-left: 17px;">
                    データ移行
                  </button>
                  <div class="collapse show" id="convert-collapse" style="margin-left: 17px;">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><router-link to="/admin/convert/green" class="text-dark d-inline-flex text-decoration-none rounded" href="#">GREEN</router-link></li>
                      <li><router-link to="/admin/convert/red" class="text-dark d-inline-flex text-decoration-none rounded" href="#">RED</router-link></li>
                    </ul>
                  </div>
                </li>
                <li>
                  <button class="btn btn-toggle d-inline-flex align-items-center rounded border-0" data-bs-toggle="collapse" data-bs-target="#convert-collapse" aria-expanded="true" style="font-weight: normal; margin-left: 17px;">
                    システム
                  </button>
                  <div class="collapse show" id="convert-collapse" style="margin-left: 17px;">
                    <ul class="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                      <li><router-link to="/admin/system/index" class="text-dark d-inline-flex text-decoration-none rounded" href="#">インデックス再構築</router-link></li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li v-if="session.session.hasPicRole === 'YES'" class="mb-1">
            <router-link to="/common/contract/disp" class="btn d-inline-flex align-items-center rounded border-0">
              <b><i class="fas fa-cog" style="margin-right: 5px;"></i>契約</b>
            </router-link>
          </li>
          <li class="mb-1">
            <router-link to="/common/account/disp" class="btn d-inline-flex align-items-center rounded border-0" href="#">
              <b><i class="fas fa-user" style="margin-right: 5px;"></i>Myアカウント</b>
            </router-link>
          </li>
          <hr>
          <li class="mb-1">
            <a hreflang="#" @click="router.go()" class="btn d-inline-flex align-items-center rounded border-0" href="#">
              <b><i class="fas fa-rotate-right" style="margin-right: 5px;"></i>再読み込み</b>
            </a>
          </li>
          <li class="mb-1">
            <a hreflang="#" @click="logout" class="btn d-inline-flex align-items-center rounded border-0" href="#">
              <b><i class="fas fa-sign-out" style="margin-right: 5px;"></i>ログアウト</b>
            </a>
          </li>
        </ul>
        <hr>
      </div>
    </div>
<!--    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">-->
<!--      <div class="container-fluid">-->
<!--        <router-link to="/portal" class="navbar-brand"><img src="../assets/logo_nav.png" height="24"></router-link>-->
<!--        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">-->
<!--          <span class="navbar-toggler-icon"></span>-->
<!--        </button>-->
<!--        <div class="collapse navbar-collapse" id="navbarSupportedContent">-->
<!--          <ul class="navbar-nav me-auto mb-2 mb-lg-0">-->
<!--            <li v-if="session && !session.contractId && session.hasAdminRole === 'NO'" class="nav-item">-->
<!--              <router-link to="/green/receipt" class="nav-link active" aria-current="page" href="#" style="color: #8EC31F;">-->
<!--                <i class="fas fa-bookmark fa-fw" style="margin-right: 5px;"></i>GREEN-->
<!--              </router-link>-->
<!--            </li>-->
<!--            <li v-show="session && session.pink" class="nav-item dropdown">-->
<!--              <a class="nav-link dropdown-toggle" href="#" id="navbarPink" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="color: #EE86A8;">-->
<!--                <i class="fas fa-bookmark fa-fw" style="margin-right: 5px;"></i>PINK-->
<!--              </a>-->
<!--            </li>-->
<!--            <li v-show="session && session.hasAdminRole === 'NO' && !session.contractId" class="nav-item">-->
<!--              <router-link to="/help" class="nav-link active" aria-current="page" target="_blank"><i class="fas fa-question-circle" style="margin-right: 5px;"></i>ヘルプ</router-link>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->
<!--    </nav>-->
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import router from '@/router'
// import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { naviStore } from './store'
import { redCompanyFunction } from '@/functions/common'
import { errorFunction } from '@/views/red/error/function'

export default defineComponent({
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const store = naviStore()
    const menuMode = ref('normal')
    // エラーバッジ
    const redUmErrorCount = ref(0)
    const redNdErrorCount = ref(0)
    const redUseTypeErrorCount = ref(0)
    const companyFunc = redCompanyFunction()
    const errorFunc = errorFunction()
    // Method
    const logout = () => {
      localStorage.removeItem('access_token')
      sessionStorage.removeItem('access_token')
      session.session = null
      router.push('/login')
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      if (session.session.red) {
        const divTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
        redUmErrorCount.value = await errorFunc.getUnMatchCount(divTerm.id)
        redNdErrorCount.value = await errorFunc.getNotDivCount(divTerm.id)
        redUseTypeErrorCount.value = await errorFunc.getUseTypeErrorCount(session.session.company.id)
      }
      console.log(session.session)
    //   if (session.session && session.session.company && session.session.company.id) {
    //     axios.get(process.env.VUE_APP_RED_ENDPOINT + '/error/unmatch/count', {
    //       headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    //       params: {
    //         company_id: session.session.company.id
    //       }
    //     }).then(response => {
    //       redUmErrorCount.value = response.data
    //     })
    //     axios.get(process.env.VUE_APP_RED_ENDPOINT + '/error/notdiv/count', {
    //       headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
    //       params: {
    //         company_id: session.session.company.id
    //       }
    //     }).then(response => {
    //       redNdErrorCount.value = response.data
    //     })
    //   }
    })
    return {
      router,
      session,
      logout,
      store,
      menuMode,
      redUmErrorCount,
      redNdErrorCount,
      redUseTypeErrorCount
    }
  }
})

</script>
