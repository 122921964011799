<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-6 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item">
                    <router-link to="/red/contract/list"><i class="fas fa-calendar-lines-pen fa-fw" style="margin-right: 5px;"></i> 契約書一覧</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/red/contract/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;">
                <i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集
              </router-link>
              <router-link v-if="form && form.id" :to="'/red/contract/copy/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;">
                <i class="fas fa-copy fa-fw" style="margin-right: 5px;"></i>コピー
              </router-link>
              <router-link to="/red/contract/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
        <div class="container">
          <div class="row">
            <div class="col-lg-7">
              <div v-if="form.code" class="form-label align-bottom">管理コード</div>
              <div v-if="form.code" class="form-edit-line">
                {{ form.code }}
              </div>
              <div class="form-label align-bottom">名前</div>
              <div class="form-edit-line fs-5" style="font-weight: bold;">
                {{ form.name }}
              </div>
              <div class="form-label align-bottom">権利者</div>
              <div class="form-edit-line">
                {{ form.rightsHolderName }}
              </div>
              <div class="form-label align-bottom">ステータス</div>
              <div class="form-edit-line">
                <span v-if="form.status === 'agree'" class="badge bg-success">契約中</span>
                <span v-else-if="form.status === 'not_agree'" class="badge bg-secondary">未契約（保留）</span>
                <span v-else-if="form.status === 'not_pay'" class="badge bg-primary">全て自社取分とする</span>
              </div>
              <div class="form-label align-bottom">免税事業者に対する扱い</div>
              <div class="form-edit-line">
                <span v-if="form.useContractSetting">契約書で個別設定</span>
                <span v-else>会社の設定に従う</span>
              </div>
              <div v-if="form.useContractSetting" class="form-label align-bottom">免税事業者に対する消費税相当額の扱い</div>
              <div v-if="form.useContractSetting" class="form-edit-line">
                <span v-if="form.invoiceExemptionTaxPayable==='pay'">課税事業者と同様に全額支払う</span>
                <span v-if="form.invoiceExemptionTaxPayable==='deduct'">消費税相当額を支払額から控除する</span>
              </div>
              <div v-show="form.useContractSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">少額特例への対応</div>
              <div v-show="form.useContractSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <span v-if="form.invoiceSmallException==='adapt'">対応する</span>
                <span v-if="form.invoiceSmallException==='not_adapt'">対応しない</span>
              </div>
              <div v-show="form.useContractSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-label align-bottom">経過措置への対応</div>
              <div v-show="form.useContractSetting && form.invoiceExemptionTaxPayable === 'deduct'" class="form-edit-line">
                <span v-if="form.invoiceTransitionalMeasures==='adapt'">対応する</span>
                <span v-if="form.invoiceTransitionalMeasures==='not_adapt'">対応しない</span>
              </div>
              <div v-if="form && form.conditionList && form.conditionList.length > 0" class="card" style="margin-bottom: 10px;">
                <div class="card-header">支払条件</div>
                <div class="card-body" style="padding: 10px 5px 5px 10px;">
                  <table class="table table-responsive">
                    <thead>
                      <tr>
                        <th style="width: 55px;"></th>
                        <th>利用種別</th>
                        <th>頒布形態</th>
                        <th>印税種別</th>
                        <th>料率</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(condition, index) in form.conditionList" :key="index">
                        <td>
                          <button @click="openConditionModal(index)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                        </td>
                        <td>
                          <span v-if="condition.useTypeId && condition.useTypeId !== 'none'">{{condition.useTypeAbbreviation}}</span>
                          <span v-else>-</span>
                        </td>
                        <td>
                          <span v-if="condition.useTypeId && condition.useTypeId !== 'none' && condition.userSalesId && condition.userSalesId !== 'none'">{{condition.userSalesName}}</span>
                          <span v-else>-</span>
                        </td>
                        <td>{{condition.royaltyTypeAbbreviation}}</td>
                        <td>
                            <span v-if="condition.calcType === 'unit_rate' || condition.calcType === 'div_rate' || condition.calcType === 'div_rate_revenue' || condition.calcType === 'after_rate' || condition.calcType === 'after_rate_revenue'">
                              <span v-if="condition.royaltyRateType === 'decimal'">{{condition.royaltyRate}}%</span>
                              <span v-else>{{condition.royaltyRateC}} / {{condition.royaltyRateM}}</span>
                            </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-else class="card text-bg-danger" style="margin-bottom: 10px;">
                <div class="card-header">支払条件未設定</div>
              </div>
              <div v-if="form.poolList && form.poolList.length > 0" class="card" style="margin-bottom: 10px;">
                <div class="card-header">保留期</div>
                <div class="card-body" style="padding: 5px;">
                  <table class="table table-responsive">
                    <tbody>
                    <tr v-for="pool in form.poolList" :key="pool.id">
                      <td>{{pool.divYear}}年{{pool.divTerm}}期</td>
                      <td class="text-end">
                        <span v-if="pool.retroactiveTarget">解除時支払対象</span>
                        <span v-else>解除しても支払わない</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div v-if="form.memo" class="form-label align-bottom">メモ</div>
              <div v-if="form.memo" class="form-edit-line">
                <pre>{{ form.memo }}</pre>
              </div>
              <div v-if="form.memo" class="form-edit-line">
                <span v-html="form.memo"></span>
              </div>
              <div class="form-label align-bottom">登録</div>
              <div class="form-edit-line">
                <span v-html="form.createdAt"></span>（{{form.createdBy}}）
              </div>
              <div class="form-label align-bottom">最終更新</div>
              <div class="form-edit-line">
                <span v-html="form.updatedAt"></span>（{{form.updatedBy}}）
              </div>
            </div>
            <div class="col-lg-5">
              <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                <li class="nav-item">
                  <a @click="rightAreaMode = 'MASTER'" class="nav-link" :class="{ 'active': rightAreaMode === 'MASTER' }" aria-current="page" href="#">
                    <i class="fas fa-file-music" style="margin-right: 5px;"></i>原盤
                  </a>
                </li>
                <li class="nav-item">
                  <a @click="rightAreaMode = 'ALBUM'" class="nav-link" :class="{ 'active': rightAreaMode === 'ALBUM' }" aria-current="page" href="#">
                    <i class="fas fa-album" style="margin-right: 5px;"></i>アルバム
                  </a>
                </li>
              </ul>
              <div v-if="rightAreaMode === 'ALBUM'">
                <div class="input-group">
                  <input @keydown.enter="albumList = []; albumCondition.page = 0; findAlbum();" v-model="albumCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                  <button @click="albumList = []; albumCondition.searchString = ''; albumCondition.page = 0; findAlbum();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  <button @click="albumList = []; albumCondition.page = 0; findAlbum();" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
                <div class="text-start" style="margin-top: 10px;">
                  <span v-if="albumResult && albumResult.allRecords > 0">{{ albumResult.allRecords }}件の一致データ</span>
                  <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                </div>
                <table v-if="albumList.length > 0" class="table table-responsive table-hover">
                  <tbody>
                    <tr v-for="album in albumList" :key="album.id" class="align-middle">
                      <td style="width: 55px;">
                        <button @click="openAlbumRefModal(album.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                      </td>
                      <td>
                        <p v-if="album.catalogNo" style="margin: 0 0 3px 0; font-size: 80%;">{{album.catalogNo}}</p>
                        <p style="margin: 0;">{{ album.name }}</p>
                        <p v-if="album.artistName" style="margin: 3px 0 0 0; font-size: 80%;">アーティスト : {{album.artistName}}</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-if="albumResult.allRecords > albumList.length">
                    <tr>
                      <td colspan="5">
                        <div class="d-grid gap-2">
                          <button @click="albumCondition.page = albumResult.page + 1; findAlbum();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div v-if="rightAreaMode === 'MASTER'">
                <div class="input-group">
                  <input @keydown.enter="masterList = []; masterCondition.page = 0; findMaster();" v-model="masterCondition.searchString" type="text" class="form-control" placeholder="検索条件">
                  <button @click="masterList = []; masterCondition.searchString = ''; masterCondition.page = 0; findMaster();" class="btn btn-outline-primary" title="クリア"><i class="fas fa-undo"></i></button>
                  <button @click="masterList = []; masterCondition.page = 0; findMaster();" class="btn btn-primary"><i class="fas fa-search"></i></button>
                </div>
                <div class="text-start" style="margin-top: 10px;">
                  <span v-if="masterResult && masterResult.allRecords > 0">{{ masterResult.allRecords }}件の一致データ</span>
                  <span v-else style="margin-top: 20px;">一致データが存在しません</span>
                </div>
                <table v-if="masterList.length > 0" class="table table-responsive table-hover">
                  <tbody>
                    <tr v-for="master in masterList" :key="master.id" class="align-middle">
                      <td style="width: 55px;">
                        <button @click="openMasterRefModal(master.id)" class="btn btn-outline-primary btn-sm"><i class="fas fa-magnifying-glass-plus fa-fw"></i></button>
                      </td>
                      <td style="vertical-align: middle;">
                        <p v-if="master.isrc" style="margin: 0 0 3px 0; font-size: 80%;"><span v-html="master.isrc"></span></p>
                        <p style="margin: 0;">{{ master.displayName }}</p>
                        <p v-if="master.artistName" style="margin: 3px 0 0 0; font-size: 80%;">アーティスト : {{master.artistName}}</p>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot v-if="masterResult.allRecords > masterList.length">
                    <tr>
                      <td colspan="5">
                        <div class="d-grid gap-2">
                          <button @click="masterCondition.page = masterResult.page + 1; findMaster();" class="btn btn-primary"><i class="fas fa-arrow-down" style="margin-right: 5px;"></i>もっと読み込む</button>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
              <div v-if="rightAreaMode === 'CONDITION'">
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="albumModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アルバムコード</h5>
          </div>
          <div class="modal-body text-start">
            <table v-if="form && form.albumList" class="table table-responsive table-hover">
              <thead>
                <tr>
                  <th style="width: 150px;">製品番号</th>
                  <th>タイトル</th>
                  <th>アーティスト</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="album in form.albumList" :key="album.albumId" style="cursor: pointer;" @click="gotoAlbum(album.albumId)">
                  <td>{{album.catalogNo}}</td>
                  <td>{{album.albumName}}</td>
                  <td>{{album.artistName}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="masterModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">原盤コード</h5>
          </div>
          <div class="modal-body text-start">
            <table v-if="form && form.masterList" class="table table-responsive table-hover">
              <thead>
              <tr>
                <th style="width: 160px;">ISRC</th>
                <th>タイトル</th>
                <th>アーティスト</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="master in form.masterList" :key="master.masterId" style="cursor: pointer;" @click="gotoMaster(master.masterId)">
                <td><span v-html="master.isrc"></span></td>
                <td>{{master.masterName}}</td>
                <td>{{master.artistName}}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="conditionModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">支払条件</h5>
          </div>
          <div v-if="conditionForm" class="modal-body text-start">
            <div class="form-label align-bottom">利用種別</div>
            <div class="form-edit-line">
              <span v-if="conditionForm.useTypeId && conditionForm.useTypeId !== 'none'">{{conditionForm.useTypeName}}</span>
              <span v-else>-</span>
            </div>
            <div v-if="conditionForm.userSalesId" class="form-label align-bottom">頒布形態</div>
            <div v-if="conditionForm.userSalesId" class="form-edit-line">
              <span v-if="conditionForm.useTypeId && conditionForm.useTypeId !== 'none' && conditionForm.userSalesId && conditionForm.userSalesId !== 'none'">{{conditionForm.userSalesName}}</span>
              <span v-else>-</span>
            </div>
            <div class="form-label align-bottom">印税種別</div>
            <div class="form-edit-line">
              {{conditionForm.royaltyTypeName}}
            </div>
            <div class="form-label align-bottom">計算式</div>
            <div class="form-edit-line" style="margin-bottom: 0;">
              <span v-if="conditionForm.calcType === 'unit_rate'">単価計算</span>
              <span v-if="conditionForm.calcType === 'unit_price'">固定単価</span>
              <span v-if="conditionForm.calcType === 'div_rate'">分配率（受領額）</span>
              <span v-if="conditionForm.calcType === 'div_rate_revenue'">分配率（売上）</span>
              <span v-if="conditionForm.calcType === 'fixPrice'">固定金額</span>
              <span v-if="conditionForm.calcType === 'after_rate'">手残り計算（受領額）</span>
              <span v-if="conditionForm.calcType === 'after_rate_revenue'">手残り計算（売上）</span>
            </div>
            <div class="form-edit-line">
              ※計算方法 :
              <span v-if="conditionForm.calcType === 'unit_rate'">算出単価（(価格 - ジャケット代) x 印税率）x 計算対象数（使用数 x 出庫率）</span>
              <span v-if="conditionForm.calcType === 'unit_price'">固定単価 x 計算対象数（使用数 x 出庫率）</span>
              <span v-if="conditionForm.calcType === 'div_rate'">(受領額 - トップオフ) x 分配率</span>
              <span v-if="conditionForm.calcType === 'div_rate_revenue'">(売上 - トップオフ) x 分配率</span>
              <span v-if="conditionForm.calcType === 'fixPrice'">指定した金額</span>
              <span v-if="conditionForm.calcType === 'after_rate'">手残り額（受領額 - トップオフ - その他の支払）x 分配率</span>
              <span v-if="conditionForm.calcType === 'after_rate_revenue'">手残り額（売上 - トップオフ - その他の支払）x 分配率</span>
            </div>
            <div v-show="conditionForm.calcType === 'unit_rate' || conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'div_rate_revenue' || conditionForm.calcType === 'after_rate' || conditionForm.calcType === 'after_rate_revenue'" class="form-label align-bottom">
              <span v-if="conditionForm.calcType === 'unit_rate'">印税率</span>
              <span v-if="conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'div_rate_revenue' || conditionForm.calcType === 'after_rate' || conditionForm.calcType === 'after_rate_revenue'">分配率</span>
            </div>
            <div v-show="conditionForm.calcType === 'unit_rate' || conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'div_rate_revenue' || conditionForm.calcType === 'after_rate' || conditionForm.calcType === 'after_rate_revenue'" class="form-edit-line">
              <span v-if="conditionForm.royaltyRateType === 'decimal'">
                {{conditionForm.royaltyRate}}%
              </span>
              <span v-if="conditionForm.royaltyRateType === 'fraction'">
                {{conditionForm.royaltyRateC}} / {{conditionForm.royaltyRateM}}
              </span>
            </div>
            <div v-if="conditionForm.calcType === 'unit_rate'" class="form-label align-bottom">
              印税単価ベース価格
            </div>
            <div v-if="conditionForm.calcType === 'unit_rate'" class="form-edit-line">
              <span v-if="conditionForm.unitPriceBasePrice === 'sales_price_out_tax'">税抜販売価格</span>
              <span v-if="conditionForm.unitPriceBasePrice === 'sales_price_in_tax'">税抜販売価格</span>
              <span v-if="conditionForm.unitPriceBasePrice === 'whole_price_out_tax'">税抜卸価格</span>
              <span v-if="conditionForm.unitPriceBasePrice === 'whole_price_in_tax'">税込卸価格</span>
              <span v-if="conditionForm.unitPriceBasePrice === 'deemed_price_out_tax'">税抜みなし価格</span>
              <span v-if="conditionForm.unitPriceBasePrice === 'deemed_price_in_tax'">税込みなし価格</span>
              <span v-if="conditionForm.unitPriceBasePrice === 'deemed_whole_price_out_tax'">税抜みなし卸価格</span>
              <span v-if="conditionForm.unitPriceBasePrice === 'deemed_whole_price_in_tax'">税込みなし卸価格</span>
            </div>
            <div v-if="conditionForm.calcType === 'unit_rate'" class="form-label align-bottom">
              ジャケット代ベース価格
            </div>
            <div v-if="conditionForm.calcType === 'unit_rate'" class="form-edit-line">
              <span v-if="conditionForm.jacketDeductionBasePrice === 'sales_price_out_tax'">税抜販売価格</span>
              <span v-if="conditionForm.jacketDeductionBasePrice === 'sales_price_in_tax'">税抜販売価格</span>
              <span v-if="conditionForm.jacketDeductionBasePrice === 'whole_price_out_tax'">税抜卸価格</span>
              <span v-if="conditionForm.jacketDeductionBasePrice === 'whole_price_in_tax'">税込卸価格</span>
              <span v-if="conditionForm.jacketDeductionBasePrice === 'deemed_price_out_tax'">税抜みなし価格</span>
              <span v-if="conditionForm.jacketDeductionBasePrice === 'deemed_price_in_tax'">税込みなし価格</span>
              <span v-if="conditionForm.jacketDeductionBasePrice === 'deemed_whole_price_out_tax'">税抜みなし卸価格</span>
              <span v-if="conditionForm.jacketDeductionBasePrice === 'deemed_whole_price_in_tax'">税込みなし卸価格</span>
            </div>
            <div v-if="conditionForm.unitPriceBasePrice && (conditionForm.unitPriceBasePrice.includes('whole_price') || conditionForm.jacketDeductionBasePrice.includes('whole_price'))" class="form-label align-bottom">
              卸価格算出方法
            </div>
            <div v-if="conditionForm.unitPriceBasePrice && (conditionForm.unitPriceBasePrice.includes('whole_price') || conditionForm.jacketDeductionBasePrice.includes('whole_price'))" class="form-edit-line">
              <span v-if="conditionForm.wholePriceCalcType === 'direct'">アルバム情報または実績データ内の卸価格を使用</span>
              <span v-if="conditionForm.wholePriceCalcType === 'calculate'">税抜販売価格から卸価格を算出</span>
            </div>
            <div v-show="conditionForm.wholePriceCalcType === 'calculate'" class="form-label align-bottom">卸価格率</div>
            <div v-show="conditionForm.wholePriceCalcType === 'calculate'" class="form-edit-line">
              <span v-if="conditionForm.wholePriceRateType === 'decimal'">
                {{conditionForm.wholePriceRate}}%
              </span>
              <span v-if="conditionForm.wholePriceRateType === 'fraction'">
                {{conditionForm.wholePriceRateC}} / {{conditionForm.wholePriceRateM}}
              </span>
            </div>
            <div v-show="conditionForm.calcType === 'unit_price'" class="form-label align-bottom">固定単価</div>
            <div v-show="conditionForm.calcType === 'unit_price'" class="form-edit-line">
              ￥{{conditionForm.unitPrice}}
            </div>
            <div v-show="conditionForm.calcType === 'fixPrice'" class="form-label align-bottom">固定金額</div>
            <div v-show="conditionForm.calcType === 'fixPrice'" class="form-edit-line">
              ￥{{conditionForm.fixPrice}}
            </div>
            <div v-show="conditionForm.calcType === 'fixPrice'" class="form-label align-bottom">支払回数</div>
            <div v-show="conditionForm.calcType === 'fixPrice'" class="form-edit-line">
              <span v-if="conditionForm.fixPricePayCountType === 'one'">1回</span>
              <span v-if="conditionForm.fixPricePayCountType === 'forever'">無制限</span>
            </div>
            <div v-show="conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'after_rate'" class="form-label align-bottom">トップオフ</div>
            <div v-show="conditionForm.calcType === 'div_rate' || conditionForm.calcType === 'after_rate'" class="form-edit-line">
              <table class="table table-responsive">
                <tbody>
                <tr v-for="(topOff, index) in conditionForm.topOffList" :key="index">
                  <td>
                    {{topOff.title}}
                  </td>
                  <td style="width: 90px;">
                    <span v-if="topOff.topOffType === 'rate'">率</span>
                    <span v-else>額</span>
                  </td>
                  <td v-if="topOff.topOffType === 'rate'" style="width: 120px;">
                    <span v-if="topOff.topOffRateType === 'decimal'">百分率</span>
                    <span v-else>分数</span>
                  </td>
                  <td v-if="topOff.topOffType === 'rate'">
                    <span v-if="topOff.topOffRateType === 'decimal'">{{topOff.topOffRate}}</span>
                    <span v-if="topOff.topOffRateType === 'decimal'">%</span>
                    <span v-if="topOff.topOffRateType === 'fraction'">{{topOff.topOffRateC}}</span>
                    <span v-if="topOff.topOffRateType === 'fraction'">/</span>
                    <span v-if="topOff.topOffRateType === 'fraction'">{{topOff.topOffRateM}}</span>
                  </td>
                  <td v-if="topOff.topOffType === 'amount'" colspan="2">
                    <span>￥</span>
                    <span>{{topOff.topOffAmount.toLocaleString()}}</span>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-show="conditionForm.calcType === 'unit_rate'" class="form-label align-bottom">ジャケット代控除率</div>
            <div v-show="conditionForm.calcType === 'unit_rate'" class="form-edit-line">
              <span v-if="conditionForm.jacketDeductionRateType === 'decimal'">
                {{conditionForm.jacketDeductionRate}}%
              </span>
              <span v-if="conditionForm.jacketDeductionRateType === 'fraction'">
                {{conditionForm.jacketDeductionRateC}} / {{conditionForm.jacketDeductionRateM}}
              </span>
            </div>
            <div v-show="conditionForm.calcType === 'unit_rate' || conditionForm.calcType === 'unit_price'" class="form-label align-bottom">出庫率</div>
            <div v-show="conditionForm.calcType === 'unit_rate' || conditionForm.calcType === 'unit_price'" class="form-edit-line">
              <span v-if="conditionForm.shipRateType === 'decimal'">
                {{conditionForm.shipRate}}%
              </span>
              <span v-if="conditionForm.shipRateType === 'fraction'">
                {{conditionForm.shipRateC}} / {{conditionForm.shipRateM}}
              </span>
            </div>
            <div v-if="conditionForm.slide" class="form-label align-bottom">適用条件</div>
            <div v-if="conditionForm.slide" class="form-edit-line">
              <span v-if="conditionForm.slideTarget === 'ship_to'">
                累計使用数が{{conditionForm.slideEndNo.toLocaleString()}}<span v-if="conditionForm.slideEndNo_type === 'lte'">以下</span><span v-else>未満</span>
              </span>
              <span v-if="conditionForm.slideTarget === 'ship_from'">
                累計使用数が{{conditionForm.slideStartNo.toLocaleString()}}<span v-if="conditionForm.slideStartNo_type === 'gte'">以上</span><span v-else>より大きい</span>
              </span>
              <span v-if="conditionForm.slideTarget === 'ship_duration'">
                累計使用数が{{conditionForm.slideStartNo.toLocaleString()}}<span v-if="conditionForm.slideStartNo_type === 'gte'">以上</span><span v-else>より大きい</span>{{conditionForm.slideEndNo.toLocaleString()}}<span v-if="conditionForm.slideEndNo_type === 'lte'">以下</span><span v-else>未満</span>
              </span>
              <span v-if="conditionForm.slideTarget === 'amount_to'">
                累計受領額が{{conditionForm.slideEndNo.toLocaleString()}}<span v-if="conditionForm.slideEndNo_type === 'lte'">以下</span><span v-else>未満</span>
              </span>
              <span v-if="conditionForm.slideTarget === 'amount_from'">
                累計受領額が{{conditionForm.slideStartNo.toLocaleString()}}<span v-if="conditionForm.slideStartNo_type === 'gte'">以上</span><span v-else>より大きい</span>
              </span>
              <span v-if="conditionForm.slideTarget === 'amount_duration'">
                累計受領額が{{conditionForm.slideStartNo.toLocaleString()}}<span v-if="conditionForm.slideStartNo_type === 'gte'">以上</span><span v-else>より大きい</span>{{conditionForm.slideEndNo.toLocaleString()}}<span v-if="conditionForm.slideEndNo_type === 'lte'">以下</span><span v-else>未満</span>
              </span>
              <span v-if="conditionForm.slideTarget === 'payment_to'">
                累計支払額が{{conditionForm.slideEndNo.toLocaleString()}}<span v-if="conditionForm.slideEndNo_type === 'lte'">以下</span><span v-else>未満</span>
              </span>
              <span v-if="conditionForm.slideTarget === 'payment_from'">
                累計支払額が{{conditionForm.slideStartNo.toLocaleString()}}<span v-if="conditionForm.slideStartNo_type === 'gte'">以上</span><span v-else>より大きい</span>
              </span>
              <span v-if="conditionForm.slideTarget === 'payment_duration'">
                累計支払額が{{conditionForm.slideStartNo.toLocaleString()}}<span v-if="conditionForm.slideStartNo_type === 'gte'">以上</span><span v-else>より大きい</span>{{conditionForm.slideEndNo.toLocaleString()}}<span v-if="conditionForm.slideEndNo_type === 'lte'">以下</span><span v-else>未満</span>
              </span>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="masterRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refMasterId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">原盤詳細</h5>
          </div>
          <div class="modal-body text-start">
            <master-modal :master-id="refMasterId"></master-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeMasterRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoMaster(refMasterId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>原盤画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="albumRefModalDiv">
      <div class="modal-dialog modal-xl modal-dialog-scrollable">
        <div v-if="refAlbumId" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">アルバム詳細</h5>
          </div>
          <div class="modal-body text-start">
            <album-modal :album-id="refAlbumId"></album-modal>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button @click="closeAlbumRefModal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw"></i></button>
            </div>
            <div>
              <button @click="gotoAlbum(refAlbumId)" class="btn btn-outline-primary"><i class="fas fa-arrow-right" style="margin-right: 5px;"></i>アルバム画面に移動</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import navComponent from '@/components/Nav'
import { defineComponent, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import footerComponent from '@/components/Footer.vue'
import { contractFunction } from './function'
import { masterFunction } from '../master/function'
import { albumFunction } from '../album/function'
import { rightsHolderFunction } from '../rightsholder/function'
import { masterStore, albumStore } from './store'
import masterModal from '@/components/modal/MasterModal'
import albumModal from '@/components/modal/AlbumModal'

export default defineComponent({
  components: { footerComponent, navComponent, masterModal, albumModal },
  setup () {
    const auth = authFunction()
    const session = sessionStore()
    const route = useRoute()
    const router = useRouter()
    const albumName = ref(null)
    const masterName = ref(null)
    const rightsHolderName = ref(null)
    const rightsHolderFunc = rightsHolderFunction()
    // Form
    const contractFunc = contractFunction()
    const form = ref(null)
    const rightAreaMode = ref('MASTER')
    const albumModalDiv = ref(null)
    let albumModal = null
    const masterModalDiv = ref(null)
    let masterModal = null
    const conditionModalDiv = ref(null)
    let conditionModal = null
    const conditionForm = ref(null)
    // 原盤
    const masterFunc = masterFunction()
    const masterCondition = masterStore()
    const masterResult = ref({})
    const masterList = ref([])
    // アルバム
    const albumFunc = albumFunction()
    const albumCondition = albumStore()
    const albumResult = ref({})
    const albumList = ref([])
    // 原盤詳細
    const masterRefModalDiv = ref({})
    let masterRefModal = null
    const refMasterId = ref('')
    // アルバム詳細
    const albumRefModalDiv = ref(null)
    let albumRefModal = null
    const refAlbumId = ref('')
    // メソッド
    const init = async (id) => {
      albumCondition.page = 0
      albumCondition.searchString = ''
      masterCondition.page = 0
      masterCondition.searchString = ''
      form.value = await contractFunc.get(id)
      await findAlbum()
      await findMaster()
      if (route.path.includes('/red/album')) {
        const album = await albumFunc.get(route.params.albumId)
        albumName.value = album.name
        if (albumName.value.length > 15) {
          albumName.value = albumName.value.substring(0, 15) + '...'
        }
      }
      if (route.path.includes('/red/master')) {
        const master = await masterFunc.get(route.params.masterId)
        masterName.value = master.name
        if (masterName.value.length > 15) {
          masterName.value = masterName.value.substring(0, 15) + '...'
        }
      }
      if (route.path.includes('/red/rightsholder')) {
        const rightsHolder = await rightsHolderFunc.get(route.params.rightsHolderId)
        rightsHolderName.value = rightsHolder.name
        if (rightsHolderName.value.length > 15) {
          rightsHolderName.value = rightsHolderName.value.substring(0, 15) + '...'
        }
      }
    }
    const openAlbumModal = () => {
      albumModal.show()
    }
    const openMasterModal = () => {
      masterModal.show()
    }
    const openConditionModal = (index) => {
      conditionForm.value = form.value.conditionList[index]
      conditionModal.show()
    }
    const gotoAlbum = (albumId) => {
      albumRefModal.hide()
      router.push('/red/album/disp/' + albumId)
    }
    const gotoMaster = (masterId) => {
      masterRefModal.hide()
      router.push('/red/master/disp/' + masterId)
    }
    const findMaster = async () => {
      masterResult.value = await masterFunc.findByContract(form.value.id, masterCondition.searchString, masterCondition.page)
      masterList.value = masterList.value.concat(masterResult.value.list)
    }
    const findAlbum = async () => {
      albumResult.value = await albumFunc.findByContract(form.value.id, albumCondition.searchString, albumCondition.page)
      albumList.value = albumList.value.concat(albumResult.value.list)
    }
    const openMasterRefModal = async (masterId) => {
      refMasterId.value = masterId
      masterRefModal.show()
    }
    const closeMasterRefModal = () => {
      masterRefModal.hide()
    }
    const openAlbumRefModal = async (albumId) => {
      refAlbumId.value = albumId
      albumRefModal.show()
    }
    const closeAlbumRefModal = () => {
      albumRefModal.hide()
    }
    onMounted(async () => {
      albumModal = new Modal(albumModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      masterModal = new Modal(masterModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      conditionModal = new Modal(conditionModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      masterRefModal = new Modal(masterRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      albumRefModal = new Modal(albumRefModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      router,
      route,
      albumName,
      masterName,
      rightsHolderName,
      form,
      rightAreaMode,
      albumModalDiv,
      masterModalDiv,
      openAlbumModal,
      openMasterModal,
      gotoAlbum,
      gotoMaster,
      conditionModalDiv,
      conditionForm,
      openConditionModal,
      masterCondition,
      findMaster,
      masterResult,
      masterList,
      albumCondition,
      findAlbum,
      albumResult,
      albumList,
      masterRefModalDiv,
      albumRefModalDiv,
      openAlbumRefModal,
      closeAlbumRefModal,
      openMasterRefModal,
      closeMasterRefModal,
      refAlbumId,
      refMasterId
    }
  }
})
</script>
