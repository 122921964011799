<template>
  <div>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center">
            <div class="d-none d-md-block col-md-8 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item"><router-link to="/green/destination/list"><i class="fas fa-inbox-out fa-fw" style="margin-right: 5px;"></i> 送付先一覧</router-link></li>
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-eye" style="margin-right: 5px;"></i>閲覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <router-link v-if="form && form.id" :to="'/green/destination/edit/' + form.id" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-edit fa-fw" style="margin-right: 5px;"></i>編集</router-link>
              <router-link to="/green/destination/add" class="btn btn-outline-primary"><i class="fas fa-plus fa-fw" style="margin-right: 5px;"></i>追加</router-link>
            </div>
          </div>
        </div>
        <div v-if="form" class="text-start" style="position: fixed; top: 65px; left: 270px; right: 30px; bottom: 50px; overflow: auto;">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <div class="form-label align-bottom">名前</div>
                <div class="form-edit-line">
                  {{form.name}}
                  <span v-if="!form.name"><br></span>
                </div>
                <div class="form-label align-bottom">メールアドレス</div>
                <div class="form-edit-line">
                  <p v-for="email in emailList" :key="email" style="padding: 0; margin: 0;">{{email}}</p>
                  <span v-if="!emailList || emailList.length === 0">非公開</span>
                </div>
                <div class="form-label align-bottom">敬称</div>
                <div class="form-edit-line">
                  <span v-if="form.honorificPosition">
                    <span v-if="form.honorificPosition === 'AFTER'">〇〇</span>
                    {{form.honorificTitle}}
                    <span v-if="form.honorificPosition === 'BEFORE'">〇〇</span>
                  </span>
                  <span v-if="!form.honorificPosition">なし</span>
                </div>
                <div v-if="userList && userList.length > 0" class="form-label align-bottom">CC/BCC</div>
                <div v-if="userList && userList.length > 0" class="form-edit-line">
                  <table class="table table-responsive table-hover">
                    <tbody>
                      <tr v-for="(user, index) in userList" :key="index">
                        <td>
                          {{user.name}}
                          <p v-for="email in user.emailList" :key="email" style="margin: 0; font-size: 90%;">{{email}}</p>
                          <p v-if="!user.emailList || user.emailList.length === 0" style="margin: 0; font-size: 90%;">メールアドレス非公開</p>
                        </td>
                        <td style="width: 130px;" class="text-end">
                          <span v-if="user.ccOrBcc === 'CC'" class="badge bg-info">CC</span>
                          <span v-if="user.ccOrBcc === 'BCC'" class="badge bg-info">BCC</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-if="session.session && session.session.greenCapo" class="card" style="margin-bottom: 10px;">
                  <div class="card-header">capo権利者</div>
                  <div class="card-body" style="padding: 5px;">
                    <div v-if="capoRightholderList.length === 0" style="margin-top: 10px;">未設定</div>
                    <table class="table table-responsive table-hover">
                      <tbody>
                      <tr v-for="(rightholder, index) in capoRightholderList" :key="index">
                        <td>
                          <p v-if="rightholder.code" style="margin: 0; font-size: 85%;">（{{rightholder.code}}）</p>
                          {{rightholder.name}}<br>
                          <span style="font-size: 90%;">(会社：{{rightholder.companyName}})</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-if="session.session && session.session.greenJrca" class="card" style="margin-bottom: 10px;">
                  <div class="card-header">capo(JRCA)権利者</div>
                  <div class="card-body" style="padding: 5px;">
                    <div v-if="jrcaRightholderList.length === 0" style="margin-top: 10px;">未設定</div>
                    <table class="table table-responsive table-hover">
                      <tbody>
                      <tr v-for="(rightholder, index) in jrcaRightholderList" :key="index">
                        <td>
                          <p v-if="rightholder.code" style="margin: 0; font-size: 85%;">（{{rightholder.code}}）</p>
                          {{rightholder.name}}<br>
                          <span style="font-size: 90%;">(会社：{{rightholder.companyName}})</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-if="session.session && session.session.greenContano" class="card" style="margin-bottom: 10px;">
                  <div class="card-header">contano権利者</div>
                  <div class="card-body" style="padding: 5px;">
                    <div v-if="contanoRightholderList.length === 0" style="margin-top: 10px;">未設定</div>
                    <table class="table table-responsive table-hover">
                      <tbody>
                      <tr v-for="(rightholder, index) in contanoRightholderList" :key="index">
                        <td>
                          <p v-if="rightholder.code" style="margin: 0; font-size: 85%;">（{{rightholder.code}}）</p>
                          {{rightholder.name}}<br>
                          <span style="font-size: 90%;">(会社：{{rightholder.companyName}})</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-if="session.session && session.session.red" class="card" style="margin-bottom: 10px;">
                  <div class="card-header">RED権利者</div>
                  <div class="card-body" style="padding: 5px;">
                    <div v-if="redRightholderList.length === 0" style="margin-top: 10px;">未設定</div>
                    <table class="table table-responsive table-hover">
                      <tbody>
                      <tr v-for="(rightholder, index) in redRightholderList" :key="index">
                        <td>
                          <p v-if="rightholder.code" style="margin: 0; font-size: 85%;">（{{rightholder.code}}）</p>
                          {{rightholder.name}}<br>
                          <span style="font-size: 90%;">(会社：{{rightholder.companyName}})</span>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div v-if="form.memo" class="form-label align-bottom">メモ</div>
                <div v-if="form.memo" class="form-edit-line">
                  <div class="alert alert-secondary" v-html="form.memo.replaceAll('\n', '<br>')" style="font-size: 90%;"></div>
                </div>
              </div>
              <div class="col-lg-6">
                <span v-if="capoSentList.length === 0 && jrcaSentList.length === 0 && contanoSentList.length === 0 && redSentList.length === 0">
                  送信履歴はありません
                </span>
                <div v-else class="card" style="margin-bottom: 10px;">
                  <div class="card-header">送信履歴（直近10件）</div>
                  <div class="card-body" style="padding: 5px;">
                    <ul class="nav nav-tabs" style="margin-bottom: 10px;">
                      <li v-if="capoSentList.length > 0" class="nav-item">
                        <a @click="rightAreaMode = 'capo'" class="nav-link" :class="{ 'active': rightAreaMode === 'capo' }" aria-current="page" href="#">
                          capo
                        </a>
                      </li>
                      <li v-if="jrcaSentList.length > 0" class="nav-item">
                        <a @click="rightAreaMode = 'jrca'" class="nav-link" :class="{ 'active': rightAreaMode === 'jrca' }" aria-current="page" href="#">
                          capo(JRCA)
                        </a>
                      </li>
                      <li v-if="contanoSentList.length > 0" class="nav-item">
                        <a @click="rightAreaMode = 'contano'" class="nav-link" :class="{ 'active': rightAreaMode === 'contano' }" aria-current="page" href="#">
                          contano
                        </a>
                      </li>
                      <li v-if="redSentList.length > 0" class="nav-item">
                        <a @click="rightAreaMode = 'red'" class="nav-link" :class="{ 'active': rightAreaMode === 'red' }" aria-current="page" href="#">
                          RED
                        </a>
                      </li>
                    </ul>
                    <div v-if="rightAreaMode === 'capo'">
                      <table class="table table-striped table-responsive" style="margin-bottom: 5px;">
                        <thead>
                          <tr>
                            <td style="width: 110px;">分配期</td>
                            <td>送信日時</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="sent in capoSentList" :key="sent.id">
                            <td>{{sent.divYear}}年{{sent.divTerm}}期</td>
                            <td>{{sent.createdAt}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-if="rightAreaMode === 'jrca'">
                      <table class="table table-striped table-responsive" style="margin-bottom: 5px;">
                        <thead>
                          <tr>
                            <td style="width: 110px;">分配期</td>
                            <td>送信日時</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="sent in jrcaSentList" :key="sent.id">
                            <td>{{sent.divYear}}年{{sent.divTerm}}期</td>
                            <td>{{sent.createdAt}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-if="rightAreaMode === 'contano'">
                      <table class="table table-striped table-responsive" style="margin-bottom: 5px;">
                        <thead>
                          <tr>
                            <td style="width: 110px;">分配期</td>
                            <td>送信日時</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="sent in contanoSentList" :key="sent.id">
                            <td>{{sent.divYear}}年{{sent.divMonth}}月</td>
                            <td>{{sent.createdAt}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div v-if="rightAreaMode === 'red'">
                      <table class="table table-striped table-responsive" style="margin-bottom: 5px;">
                        <thead>
                          <tr>
                            <td style="width: 110px;">分配期</td>
                            <td>送信日時</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="sent in redSentList" :key="sent.id">
                            <td>{{sent.divYear}}年{{sent.divTerm}}期</td>
                            <td>{{sent.createdAt}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { exFunction } from '@/functions/exception'
import { sessionStore } from '@/functions/sessionStore'
import { defineComponent, onMounted, ref } from 'vue'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { useRoute } from 'vue-router'
import { destinationFunction } from './function'
import { accountFunction, redCompanyFunction } from '@/functions/common'
import { rightsHolderFunction } from '@/views/red/rightsholder/function'

export default defineComponent({
  components: { navComponent },
  setup () {
    const route = useRoute()
    const auth = authFunction()
    const ex = exFunction()
    const session = sessionStore()
    const rightAreaMode = ref(null)
    const accountFunc = accountFunction()
    // Form
    const destinationFunc = destinationFunction()
    const form = ref(null)
    const emailList = ref([])
    const userList = ref([])
    const capoRightholderList = ref([])
    const jrcaRightholderList = ref([])
    const contanoRightholderList = ref([])
    const redRightholderList = ref([])
    const capoSentList = ref([])
    const jrcaSentList = ref([])
    const contanoSentList = ref([])
    const redSentList = ref([])
    const rightsHolderFunc = rightsHolderFunction()
    const redCompanyFunc = redCompanyFunction()
    // メソッド
    function getRightholderAsync (system, id, callback) {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/rightholder/' + id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        callback(response.data)
      }).catch(error => { ex.identifyEx(error) })
    }
    function getCompanyAsync (system, id, callback) {
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/' + system + '/company/' + id, {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        callback(response.data)
      }).catch(error => { ex.identifyEx(error) })
    }
    const init = async (id) => {
      form.value = await destinationFunc.get(id)
      const account = await accountFunc.getByDestination(id)
      emailList.value = account.emailList
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/cc/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        userList.value = response.data
      }).catch(error => { ex.identifyEx(error) })
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/capo/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        capoRightholderList.value = response.data
        for (const rightholder of capoRightholderList.value) {
          getRightholderAsync('capo', rightholder.rightholderId, function (data) {
            rightholder.code = data.code
            rightholder.name = data.name
          })
          getCompanyAsync('capo', rightholder.companyId, function (data) {
            rightholder.companyName = data.name
          })
        }
      }).catch(error => { ex.identifyEx(error) })
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/jrca/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        jrcaRightholderList.value = response.data
        for (const rightholder of jrcaRightholderList.value) {
          getRightholderAsync('jrca', rightholder.rightholderId, function (data) {
            rightholder.code = data.code
            rightholder.name = data.name
          })
          getCompanyAsync('jrca', rightholder.companyId, function (data) {
            rightholder.companyName = data.name
          })
        }
      }).catch(error => { ex.identifyEx(error) })
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/contano/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        contanoRightholderList.value = response.data
        for (const rightholder of contanoRightholderList.value) {
          getRightholderAsync('contano', rightholder.rightholderId, function (data) {
            rightholder.code = data.code
            rightholder.name = data.name
          })
          getCompanyAsync('contano', rightholder.companyId, function (data) {
            rightholder.companyName = data.name
          })
        }
      }).catch(error => { ex.identifyEx(error) })
      axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/red/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(async response => {
        redRightholderList.value = response.data
        for (const rightholder of redRightholderList.value) {
          const rightsHolder = await rightsHolderFunc.get(rightholder.rightsHolderId)
          rightholder.code = rightsHolder.code
          rightholder.name = rightsHolder.name
          const company = await redCompanyFunc.get(rightsHolder.companyId)
          rightholder.companyName = company.name
        }
      }).catch(error => { ex.identifyEx(error) })
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/capo/sent/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        capoSentList.value = response.data
        if (capoSentList.value.length > 0) {
          rightAreaMode.value = 'capo'
        }
      })
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/jrca/sent/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        jrcaSentList.value = response.data
        if (jrcaSentList.value.length > 0 && !rightAreaMode.value) {
          rightAreaMode.value = 'jrca'
        }
      })
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/contano/sent/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        contanoSentList.value = response.data
        if (contanoSentList.value.length > 0 && !rightAreaMode.value) {
          rightAreaMode.value = 'contano'
        }
      })
      await axios.get(process.env.VUE_APP_GREEN_ENDPOINT + '/destination/' + id + '/red/sent/list', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
      }).then(response => {
        redSentList.value = response.data
        if (redSentList.value.length > 0 && !rightAreaMode.value) {
          rightAreaMode.value = 'red'
        }
      })
    }
    onMounted(async () => {
      session.session = await auth.getSession()
      await init(route.params.id)
    })
    return {
      session,
      rightAreaMode,
      form,
      emailList,
      userList,
      capoRightholderList,
      jrcaRightholderList,
      contanoRightholderList,
      redRightholderList,
      capoSentList,
      jrcaSentList,
      contanoSentList,
      redSentList
    }
  }
})
</script>
