<template>
  <div>
    <footer-component></footer-component>
    <main class="d-flex flex-nowrap">
      <nav-component></nav-component>
      <div class="container-fluid">
        <div style="margin-top: 10px; padding-left: 20px; padding-right: 20px;">
          <div class="row align-items-center" style="margin-top: 15px;">
            <div class="d-none d-md-block col-md-3 text-start">
              <nav aria-label="breadcrumb" style="padding: 0; margin-left: 5px;">
                <ol class="breadcrumb" style="margin: 0;">
                  <li class="breadcrumb-item active" aria-current="page"><i class="fas fa-file-plus-minus fa-fw" style="margin-right: 5px;"></i> 支払明細一覧</li>
                </ol>
              </nav>
            </div>
            <div class="col d-flex justify-content-end">
              <button @click="openDataModal" class="btn btn-outline-primary" style="margin-right: 5px;"><i class="fas fa-file"></i>データ</button>
              <span style="margin-top: 7px; margin-right: 2px;">支払日</span>
              <input v-model="paymentDate" type="date" class="form-control" style="flex: 0 1 150px;">
              <button @click="createStatement" class="btn btn-outline-primary"><i class="fas fa-list-ol" style="margin-right: 5px;"></i>作成</button>
              <button v-if="paymentStatusId && paymentStatusList.length > 0 && paymentStatusId !== paymentStatusList[0].id" @click="deleteStatus" class="btn btn-outline-danger" style="margin-left: 5px;"><i class="fas fa-timer" style="margin-right: 5px;"></i>このバージョンに戻す</button>
            </div>
            <div class="input-group" style="margin-top: 15px;">
              <input v-model="store.divYear" @change="store.page = 0; getStatusList(true);" type="number" class="form-control" style="flex: 0 1 90px;" :disabled="detailSearchMode">
              <span class="input-group-text">年</span>
              <input v-model="store.divTerm" @change="store.page = 0; getStatusList(true);" type="number" class="form-control" style="flex: 0 1 60px;" :disabled="detailSearchMode">
              <span class="input-group-text">期</span>
              <select v-if="paymentStatusList && paymentStatusList.length > 0" v-model="paymentStatusId" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 250px;" :disabled="detailSearchMode">
                <option v-for="(status, index) in paymentStatusList" :key="status.id" :value="status.id">
                  {{status.lastCreatedAt}}<span v-if="index === 0">&nbsp;[最新]</span>
                </option>
              </select>
              <input @keydown.enter="store.page = 0; find();" v-model="store.searchString" type="text" class="form-control" placeholder="検索条件" :disabled="detailSearchMode">
              <select v-model="store.likeOrNotLike" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 120px;" :disabled="detailSearchMode">
                <option value="like">含む</option>
                <option value="not_like">含まない</option>
              </select>
              <button @click="clearSearch" class="btn btn-outline-primary" title="クリア" :disabled="detailSearchMode"><i class="fas fa-undo"></i></button>
              <button @click="detailSearchMode = true" class="btn btn-outline-primary" :disabled="detailSearchMode"><i class="fas fa-filter-list"></i></button>
              <button @click="store.page = 0; find();" class="btn btn-primary" :disabled="detailSearchMode"><i class="fas fa-search"></i></button>
            </div>
          </div>
          <div v-if="lastCreatedAt" style="margin-top: 10px; margin-bottom: 5px;" class="text-end">
            最終明細作成日時：{{lastCreatedAt}}
          </div>
        </div>
        <div style="position: fixed; top: 145px; left: 270px; right: 30px; bottom: 120px; overflow: auto;">
          <div v-if="detailSearchMode" class="alert border-dark bg-white" style="margin-top: 5px; z-index: 9999;">
            <div class="row" style="margin-bottom: 10px;">
              <div class="col-8 text-start">
                【詳細検索】
              </div>
              <div class="col-4 text-end">
                <button @click="detailSearchMode = false" class="btn btn-outline-primary btn-sm"><i class="fas fa-filter-slash"></i></button>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <label class="col-2 col-form-label">検索文字</label>
                  <div class="col-10">
                    <input v-model="store.detailCondition.searchString" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">事業者</label>
                  <div class="col-10 col-xl-8">
                    <select v-model="store.detailCondition.taxationType" class="form-select">
                      <option value="all">全て</option>
                      <option value="taxation">課税事業者</option>
                      <option value="exemption">免税事業者</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">事業者登録番号</label>
                  <div class="col-10 col-xl-8">
                    <input v-model="store.detailCondition.invoiceNo" type="text" class="form-control">
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">支払／保留</label>
                  <div class="col-10 col-xl-8">
                    <select v-model="store.detailCondition.payOrCarryForward" class="form-select">
                      <option value="all">全て</option>
                      <option value="not_zero">支払・繰越全て0円の明細を除いた全部</option>
                      <option value="pay">支払</option>
                      <option value="carry_forward">保留</option>
                      <option value="none">支払・繰越全て0円</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">計算書送付方法</label>
                  <div class="col-10 col-xl-8">
                    <select v-model="store.detailCondition.statementSendType" class="form-select">
                      <option value="all">全て</option>
                      <option value="online">オンライン</option>
                      <option value="paper">郵送</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <label class="col-2 col-xl-4 col-form-label">並び順</label>
                  <div class="col-10 col-xl-8">
                    <div class="input-group">
                      <select v-model="store.detailCondition.sortColumn" @change="store.page = 0; find();" class="form-select">
                        <option value="name">名前</option>
                        <option value="kana">カナ</option>
                        <option value="code">コード</option>
                      </select>
                      <select v-model="store.detailCondition.sortOrder" @change="store.page = 0; find();" class="form-select" style="flex: 0 1 160px;">
                        <option value="asc">昇順（A → Z）</option>
                        <option value="desc">降順（Z → A）</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 text-end" style="margin-top: 10px;">
                <button @click="store.page = 0; find();" class="btn btn-outline-primary"><i class="fas fa-search" style="margin-right: 5px;"></i>検索</button>
              </div>
            </div>
          </div>
          <table v-if="paymentResult && paymentResult.allRecords > 0" class="table table-responsive table-hover" style="font-size: 90%;">
            <thead>
              <tr>
                <th style="width: 55px;"></th>
                <th style="width: 60px;">保護</th>
                <th style="width: 150px;">権利者コード</th>
                <th>権利者</th>
                <th style="width: 90px;">前期繰越</th>
                <th style="width: 90px;">今期発生</th>
                <th style="width: 90px;">支払</th>
                <th style="width: 90px;">繰越</th>
              </tr>
            </thead>
            <tbody v-if="paymentList">
              <tr v-for="payment in paymentList" :key="payment.id" class="text-start">
                <td class="text-center">
                  <router-link class="btn btn-outline-primary btn-sm" :to="'/red/payment/disp/' + payment.id"><i class="fas fa-magnifying-glass-plus fa-fw"></i></router-link>
                </td>
                <td>
                  <span v-if="payment.protect" class="badge bg-danger">保護</span>
                </td>
                <td>{{ payment.rightsHolderCode }}</td>
                <td>{{ payment.rightsHolderName }}</td>
                <td class="text-end">{{ payment.amountBroughtForward.toLocaleString() }}</td>
                <td class="text-end">{{ payment.amountInThisTerm.toLocaleString() }}</td>
                <td class="text-end">{{ payment.paymentAmount.toLocaleString() }}</td>
                <td v-if="payment.paymentAmount === 0" class="text-end">
                  <span v-if="payment.amount_carried_forward_out_tax === 0">{{payment.amountCarriedForwardInTax.toLocaleString()}}</span>
                  <span v-else>{{payment.amountCarriedForwardOutTax.toLocaleString()}}</span>
                </td>
                <td v-else class="text-end">0</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="position: fixed; bottom: 40px; left: 270px; right: 30px;">
          <div class="col-6 d-flex justify-content-start">
            <span v-if="paymentResult && paymentResult.allRecords > 0">{{ paymentResult.allRecords }}件の一致データ</span>
            <span v-else style="margin-top: 20px;">一致データが存在しません</span>
          </div>
          <div v-if="paymentResult && paymentResult.allRecords > 0" class="col-6 d-flex justify-content-end">
            <nav v-if="paymentResult" aria-label="Page navigation">
              <ul class="pagination">
                <li v-if="store.page !== 0" class="page-item"><button @click="firstPage" class="page-link" href="#"><i class="fas fa-fast-backward"></i></button></li>
                <li v-if="store.page !== 0" class="page-item"><button @click="prevPage" class="page-link" href="#"><i class="fas fa-chevron-left"></i></button></li>
                <li class="page-item"><span class="page-link">{{ store.page + 1 }}/{{ paymentResult.allPages }}ページ</span></li>
                <li v-if="store.page !== paymentResult.allPages - 1" class="page-item"><button @click="nextPage" class="page-link" href="#"><i class="fas fa-chevron-right"></i></button></li>
                <li v-if="store.page !== paymentResult.allPages - 1" class="page-item"><button @click="lastPage" class="page-link" href="#"><i class="fas fa-fast-forward"></i></button></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </main>
    <div class="modal" tabindex="-1" ref="dataModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">データダウンロード</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>対象データ</div>
            <div class="form-edit-line">
              <div class="form-check form-check-inline">
                <input @change="changeDataType" v-model="dataType" class="form-check-input" type="radio" name="dataType" id="dataTypeList" value="list">
                <label class="form-check-label" for="dataTypeList">一覧表</label>
              </div>
              <div class="form-check form-check-inline">
                <input @change="changeDataType" v-model="dataType" class="form-check-input" type="radio" name="dataType" id="dataTypeDetail" value="detail">
                <label class="form-check-label" for="dataTypeDetail">明細</label>
              </div>
              <div class="form-check form-check-inline">
                <input @change="changeDataType" v-model="dataType" class="form-check-input" type="radio" name="dataType" id="dataTypeSelf" value="self">
                <label class="form-check-label" for="dataTypeSelf">自社明細（全件）</label>
              </div>
              <div class="form-check form-check-inline">
                <input @change="changeDataType" v-model="dataType" class="form-check-input" type="radio" name="changeDataType" id="dataTypeTransfer" value="transfer">
                <label class="form-check-label" for="dataTypeTransfer">銀行振込データ</label>
              </div>
            </div>
            <div v-if="dataType === 'list'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>一覧形式</div>
            <div v-if="dataType === 'list'" class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="listType" class="form-check-input" type="radio" name="listType" id="listTypeNormal" value="normal">
                <label class="form-check-label" for="listTypeNormal">標準</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="listType" class="form-check-input" type="radio" name="listType" id="listTypeTax" value="tax">
                <label class="form-check-label" for="listTypeTax">消費税内訳付き</label>
              </div>
            </div>
            <div v-if="dataType !== 'transfer'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>データフォーマット</div>
            <div v-if="dataType !== 'transfer'" class="form-edit-line">
              <div v-if="dataType === 'detail'" class="form-check form-check-inline">
                <input v-model="fileType" class="form-check-input" type="radio" name="fileType" id="fileTypePdf" value="pdf">
                <label class="form-check-label" for="fileTypePdf">PDFファイル</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="fileType" class="form-check-input" type="radio" name="fileType" id="fileTypeXlsx" value="xlsx">
                <label class="form-check-label" for="fileTypeXlsx">Excelファイル</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="fileType" class="form-check-input" type="radio" name="fileType" id="fileTypeTsv" value="tsv">
                <label class="form-check-label" for="fileTypeTsv">タブ区切りテキスト</label>
              </div>
            </div>
            <div v-if="fileType === 'tsv'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>文字コード</div>
            <div v-if="fileType === 'tsv'" class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="fileEncoding" class="form-check-input" type="radio" name="encoding" id="encodingSjis" value="Shift_JIS">
                <label class="form-check-label" for="encodingSjis">Shift_JIS</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="fileEncoding" class="form-check-input" type="radio" name="encoding" id="encodingUtf8" value="UTF-8">
                <label class="form-check-label" for="encodingUtf8">UTF-8</label>
              </div>
            </div>
            <div v-if="dataType === 'detail' && fileType === 'pdf'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>ファイル単位</div>
            <div v-if="dataType === 'detail' && fileType === 'pdf'" class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="makeZip" class="form-check-input" type="radio" name="makeZip" id="makeZipFalse" value="false">
                <label class="form-check-label" for="makeZipFalse">1つのファイルに全明細</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="makeZip" class="form-check-input" type="radio" name="makeZip" id="makeZipTrue" value="true">
                <label class="form-check-label" for="makeZipTrue">明細毎にファイルを作成し、ZIPファイルでダウンロード</label>
              </div>
            </div>
            <div v-if="fileType === 'tsv'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>改行コード</div>
            <div v-if="fileType === 'tsv'" class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="returnCode" class="form-check-input" type="radio" name="returnCode" id="returnCodeWin" value="windows">
                <label class="form-check-label" for="returnCodeWin">Windows形式（CRLF）</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="returnCode" class="form-check-input" type="radio" name="returnCode" id="returnCodeUnix" value="unix">
                <label class="form-check-label" for="returnCodeUnix">Unix形式（LF）</label>
              </div>
            </div>
            <div v-if="fileType === 'pdf'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>両面印刷用</div>
            <div v-if="fileType === 'pdf'" class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="duplexPrint" class="form-check-input" type="radio" name="duplexPrint" id="duplexPrintFalse" value="false">
                <label class="form-check-label" for="duplexPrintFalse">空白ページで調整しない</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="duplexPrint" class="form-check-input" type="radio" name="duplexPrint" id="duplexPrintTrue" value="true">
                <label class="form-check-label" for="duplexPrintTrue">空白ページで調整する</label>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="downloadStart" class="btn btn-primary"><i class="fas fa-file-download" style="margin-right: 5px;"></i>ダウンロード開始</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="printModalDiv">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">印刷</h5>
          </div>
          <div class="modal-body text-start">
            <div class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>対象データ</div>
            <div class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="printType" class="form-check-input" type="radio" name="printType" id="printTypeList" value="list">
                <label class="form-check-label" for="printTypeList">一覧表</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="printType" class="form-check-input" type="radio" name="printType" id="printTypeDetail" value="detail">
                <label class="form-check-label" for="printTypeDetail">明細</label>
              </div>
            </div>
            <div v-if="printType === 'detail'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>明細行出力</div>
            <div v-if="printType === 'detail'" class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="printDetail" class="form-check-input" type="radio" name="printDetail" id="printDetailTrue" value="true">
                <label class="form-check-label" for="printDetailTrue">出力する</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="printDetail" class="form-check-input" type="radio" name="printDetail" id="printDetailFalse" value="false">
                <label class="form-check-label" for="printDetailFalse">出力しない（鑑のみ）</label>
              </div>
            </div>
            <div v-if="printType === 'detail'" class="form-label align-bottom"><i class="fas fa-exclamation-circle text-danger" style="margin-right: 5px;"></i>両面印刷用</div>
            <div v-if="printType === 'detail'" class="form-edit-line">
              <div class="form-check form-check-inline">
                <input v-model="duplexPrint" class="form-check-input" type="radio" name="duplexPrint" id="duplexPrintFalse" value="false">
                <label class="form-check-label" for="duplexPrintFalse">空白ページで調整しない</label>
              </div>
              <div class="form-check form-check-inline">
                <input v-model="duplexPrint" class="form-check-input" type="radio" name="duplexPrint" id="duplexPrintTrue" value="true">
                <label class="form-check-label" for="duplexPrintTrue">空白ページで調整する</label>
              </div>
            </div>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
              <button @click="printStart" class="btn btn-primary"><i class="fas fa-print" style="margin-right: 5px;"></i>印刷</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="processModalDiv">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">処理中</h5>
          </div>
          <div class="modal-body text-start">
            {{ processMessage }}
            <p v-if="allCount !== 0" class="text-center"><i class="fas fa-spinner fa-spin fa-3x"></i></p>
            <p v-if="allCount !== 0" class="text-center fs-5">{{count}}/{{allCount}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" tabindex="-1" ref="unknownBankInfoModalDiv">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">振込先不明</h5>
          </div>
          <div class="modal-body text-start">
            <table class="table table-responsive table-striped">
              <thead>
                <tr>
                  <th style="width: 150px;">権利者コード</th>
                  <th>権利者</th>
                  <th>不明情報</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="error in bankErrorList" :key="error.id">
                  <td>{{error.rightsHolderCode}}</td>
                  <td>{{error.rightsHolderName}}</td>
                  <td class="text-danger">{{error.errorNotes}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer d-flex">
            <div class="me-auto">
              <button data-bs-dismiss="modal" type="button" class="btn btn-secondary"><i class="fas fa-times fa-fw" style="margin-right: 5px;"></i>閉じる</button>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script>
import { authFunction } from '@/functions/auth'
import { sessionStore } from '@/functions/sessionStore'
import footerComponent from '@/components/Footer'
import navComponent from '@/components/Nav'
import axios from 'axios'
import { defineComponent, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Modal } from 'bootstrap'
import { paymentStore } from './store'
import { paymentFunction } from './function'
import { redCompanyFunction, holidayFunction } from '@/functions/common'
import { rightsHolderFunction } from '@/views/red/rightsholder/function'

export default defineComponent({
  components: { navComponent, footerComponent },
  setup () {
    const router = useRouter()
    const auth = authFunction()
    const session = sessionStore()
    const processMessage = ref('')
    const processModalDiv = ref(null)
    let processModal = null
    const unknownBankInfoModalDiv = ref(null)
    let unknownBankInfoModal = null
    const bankErrorList = ref([])
    // 検索
    const companyFunc = redCompanyFunction()
    const holidayFunc = holidayFunction()
    const paymentFunc = paymentFunction()
    const rightsHolderFunc = rightsHolderFunction()
    const store = paymentStore()
    const detailSearchMode = ref(false)
    const companyDivTermId = ref(null)
    const currentDivYear = ref(0)
    const currentDivTerm = ref(0)
    const paymentResult = ref(null)
    const paymentList = ref([])
    const lastCreatedAt = ref('')
    const paymentDate = ref(null)
    const paymentStatusList = ref([])
    const paymentStatusId = ref(null)
    // データダウンロード
    const dataModalDiv = ref(null)
    let dataModal = null
    const dataType = ref('list')
    const fileType = ref('xlsx')
    const fileEncoding = ref('Shift_JIS')
    const returnCode = ref('windows')
    const listType = ref('normal')
    // 印刷
    const printType = ref('list')
    const printModalDiv = ref(null)
    let printModal = null
    const printDetail = ref(true)
    const duplexPrint = ref('false')
    const makeZip = ref('false')
    const allCount = ref(0)
    const count = ref(0)
    // メソッド
    const find = async () => {
      if (paymentStatusId.value) {
        if (detailSearchMode.value) {
          paymentResult.value = await paymentFunc.findDetail(paymentStatusId.value)
          paymentList.value = paymentResult.value.list
        } else {
          paymentResult.value = await paymentFunc.find(paymentStatusId.value)
          paymentList.value = paymentResult.value.list
        }
        const status = await paymentFunc.getStatus(session.session.company.id)
        lastCreatedAt.value = status.lastCreatedAt
      }
    }
    const clearSearch = () => {
      paymentFunc.clear(store)
      getStatusList(true)
    }
    const firstPage = () => {
      store.page = 0
      find()
    }
    const prevPage = () => {
      store.page = store.page - 1
      find()
    }
    const nextPage = () => {
      store.page = store.page + 1
      find()
    }
    const lastPage = () => {
      store.page = paymentResult.value.allPages - 1
      find()
    }
    const createStatement = async () => {
      processMessage.value = '支払明細書を作成しています。しばらくそのままでお待ちください。'
      processModal.show()
      const rightsHolderList = await rightsHolderFunc.findForStatement(session.session.company.id)
      allCount.value = rightsHolderList.length
      const paymentStatusId = await paymentFunc.createStatus(session.session.company.id)
      let statementPromises = []
      for (const [index, rightsHolder] of Object.entries(rightsHolderList)) {
        statementPromises.push(createMain(session.session.company.id, paymentStatusId, rightsHolder.id, paymentDate.value))
        if (index % 100 === 0) {
          await Promise.all(statementPromises)
          statementPromises = []
        }
      }
      if (statementPromises.length > 0) {
        await Promise.all(statementPromises)
      }
      await paymentFunc.createSelfStatement(paymentStatusId, companyDivTermId.value)
      await getStatusList(true)
      allCount.value = 0
      count.value = 0
      processModal.hide()
    }
    const createMain = async (companyId, paymentStatusId, rightsHolderId, paymentDate) => {
      await paymentFunc.createStatement(companyId, paymentStatusId, rightsHolderId, paymentDate)
      count.value = count.value + 1
    }
    const openPrintModal = () => {
      printModal.show()
    }
    const openDataModal = () => {
      dataModal.show()
    }
    const downloadStart = async () => {
      processMessage.value = 'ダウンロードファイルの作成中です。ダウンロードが開始するまでこのままでお待ちください。'
      processModal.show()
      dataModal.hide()
      const param = {
        detailSearchMode: detailSearchMode.value,
        dataType: dataType.value,
        paymentStatusId: paymentStatusId.value,
        separator: returnCode.value,
        fileType: fileType.value,
        encoding: fileEncoding.value,
        searchString: store.searchString,
        likeOrNotLike: store.likeOrNotLike,
        makeZip: makeZip.value,
        duplexPrint: duplexPrint.value,
        listType: listType.value
      }
      if (store.payOrCarryForward && store.payOrCarryForward !== 'all') {
        param.payOrCarryForward = store.payOrCarryForward
      }
      if (store.detailCondition.searchString) {
        param.searchString = store.detailCondition.searchString
      }
      if (store.detailCondition.taxationType !== 'all') {
        param.taxationType = store.detailCondition.taxationType
      }
      if (store.detailCondition.invoiceNo) {
        param.invoiceNo = store.detailCondition.invoiceNo.replace('T', '')
      }
      if (store.detailCondition.payOrCarryForward !== 'all') {
        param.payOrCarryForward = store.detailCondition.payOrCarryForward
      }
      if (store.detailCondition.statementSendType !== 'all') {
        param.statementSendType = store.detailCondition.statementSendType
      }
      const { data } = await axios.get(process.env.VUE_APP_RED_STATEMENT_ENDPOINT + '/payment/header/list/download/all', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: param
      })
      console.log(data)
      const a = document.createElement('a')
      a.href = data
      a.click()
      processModal.hide()
    }
    const printStart = () => {
      printModal.hide()
      sessionStorage.setItem('duplexPrint', duplexPrint.value)
      if (printType.value === 'list') {
        router.push('/red/payment/print/statement/' + companyDivTermId.value)
      } else {
        router.push('/red/payment/print/ALL/' + printDetail.value)
      }
    }
    const downloadTransferData = async () => {
      await axios.get(process.env.VUE_APP_RED_ENDPOINT + '/payment/download/transfer', {
        headers: { Authorization: 'Bearer ' + auth.getAccessToken() },
        params: {
          payment_status_id: paymentStatusId.value,
          company_id: session.session.company.id
        }
      }).then(response => {
        const res = response.data
        const a = document.createElement('a')
        a.href = res[0]
        a.click()
        if (res[1].length > 0) {
          bankErrorList.value = res[1]
          for (const error of bankErrorList.value) {
            const notes = []
            if (!error.bank_code) {
              notes.push('銀行コード')
            }
            if (!error.bank_kana) {
              notes.push('銀行カナ')
            }
            if (!error.branch_code) {
              notes.push('支店コード')
            }
            if (!error.branch_kana) {
              notes.push('支店カナ')
            }
            if (!error.account_no) {
              notes.push('口座番号')
            }
            if (!error.account_kana) {
              notes.push('口座カナ')
            }
            error.errorNotes = notes.join(' / ')
          }
          unknownBankInfoModal.show()
          dataModal.hide()
        }
      })
    }
    onMounted(async () => {
      processModal = new Modal(processModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      unknownBankInfoModal = new Modal(unknownBankInfoModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      dataModal = new Modal(dataModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      printModal = new Modal(printModalDiv.value, {
        backdrop: 'static',
        keyboard: false
      })
      session.session = await auth.getSession()
      const companyDivTerm = await companyFunc.getCurrentDivTerm(session.session.company.id)
      companyDivTermId.value = companyDivTerm.id
      currentDivYear.value = companyDivTerm.divYear
      currentDivTerm.value = companyDivTerm.divTerm
      store.divYear = companyDivTerm.divYear
      store.divTerm = companyDivTerm.divTerm
      const setting = await companyFunc.getDivTermSetting(session.session.company.id, store.divTerm)
      const paymentYear = await paymentFunc.getPaymentYear(store.divYear, setting.paymentMonthYearType)
      const paymentMonth = setting.paymentMonth
      const paymentDay = await paymentFunc.getPaymentDay(paymentYear, paymentMonth, setting.paymentDate, setting.paymentDateType)
      const dayOfWeek = new Date(paymentYear, paymentMonth - 1, paymentDay).getDay()
      if (await holidayFunc.getCalendarCount(paymentYear) === 0) {
        await holidayFunc.makeCalendar(paymentYear)
      }
      const tmpDate = await paymentFunc.getPaymentDate(paymentYear, paymentMonth, paymentDay, dayOfWeek, setting.payment_on_holiday)
      paymentDate.value = tmpDate.getFullYear() + '-' + ('00' + (tmpDate.getMonth() + 1)).slice(-2) + '-' + ('00' + tmpDate.getDate()).slice(-2)
      await getStatusList(false)
    })
    const getStatusList = async (isReset) => {
      const term = await companyFunc.getDivTerm(session.session.company.id, store.divYear, store.divTerm)
      paymentStatusList.value = await paymentFunc.findStatus(term.id)
      if (!paymentStatusId.value || isReset) {
        if (paymentStatusList.value.length > 0) {
          paymentStatusId.value = paymentStatusList.value[0].id
          await find()
        } else {
          paymentStatusId.value = null
          paymentStatusList.value = []
          paymentResult.value = null
          paymentList.value = []
        }
      }
    }
    const deleteStatus = async () => {
      processMessage.value = '支払明細書を過去の状態に戻す処理を実行中です。しばらくお待ちください。'
      processModal.show()
      for (const status of paymentStatusList.value) {
        if (status.id === paymentStatusId.value) {
          break
        }
        await axios.delete(process.env.VUE_APP_RED_ENDPOINT + '/payment/status/' + status.id, {
          headers: { Authorization: 'Bearer ' + auth.getAccessToken() }
        })
      }
      await getStatusList(true)
      processModal.hide()
    }
    const changeDataType = () => {
      if (dataType.value === 'detail') {
        fileType.value = 'pdf'
      } else {
        fileType.value = 'xlsx'
      }
    }
    return {
      router,
      processMessage,
      processModalDiv,
      unknownBankInfoModalDiv,
      store,
      companyDivTermId,
      paymentResult,
      paymentList,
      find,
      clearSearch,
      firstPage,
      prevPage,
      nextPage,
      lastPage,
      createStatement,
      lastCreatedAt,
      paymentDate,
      currentDivYear,
      currentDivTerm,
      dataModalDiv,
      openDataModal,
      dataType,
      listType,
      fileType,
      fileEncoding,
      downloadStart,
      returnCode,
      paymentStatusList,
      paymentStatusId,
      getStatusList,
      deleteStatus,
      printModalDiv,
      printDetail,
      openPrintModal,
      printStart,
      printType,
      duplexPrint,
      changeDataType,
      downloadTransferData,
      makeZip,
      detailSearchMode,
      bankErrorList,
      count,
      allCount
    }
  }
})
</script>
